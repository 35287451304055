import React from 'react'
import CreateOfferType from './createOfferType'
import Create from './create'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function OfferItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <Create {...props} id={id} />
}

export default OfferItem;